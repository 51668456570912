import { PageProps } from 'gatsby';
import { DataFieldProps, Form } from 'packages/formidable';
import { client } from 'packages/innedit';
import React, { FC } from 'react';

import Title from '~/components/Header/Title';
import CMSView from '~/components/View';
import TemplateAdmin from '~/templates/admin';
import requireUser from '~/utils/requireUser';

const PageAdminCollectionCreate: FC<PageProps> = () => {
  const handleOnSubmit = async (values?: any): Promise<void> => {
    await client.collections().create({
      fields: [{ name: '.*', type: 'auto' }],
      name: values.collectionName,
    });
  };

  return (
    <TemplateAdmin>
      <CMSView>
        <div>
          <Title>{`Création d'une nouvelle collection`}</Title>
          <Form
            datas={
              {
                componentType: 'input',
                name: 'collectionName',
                required: true,
              } as DataFieldProps
            }
            name="collection"
            onSubmit={handleOnSubmit}
          />
        </div>
      </CMSView>
    </TemplateAdmin>
  );
};

export default requireUser(PageAdminCollectionCreate, 'admin');
